import React, { createContext, useEffect, useState } from 'react';
import { CardsData } from '../../../types/types';
import { getCardDatas, getUsuario } from '../../../api/api';
import { useRecoilState } from 'recoil';
import { nomeUsuario } from '../../../atom/usuario';
interface DadosCardContextProps {
  dadosCard: CardsData,
  error: Error | null | unknown,
  loading: boolean,
  user: any
}
const defautCardsData: CardsData = {
  errosLog: null,
  conciliacaoPendente: null,
  rejeicaoFiscal: null,
  aguardandoAtualizacao: null,
  sistemaUso: null
}
export const CardsDataContext = createContext<DadosCardContextProps>({
  dadosCard: defautCardsData,
  error: null,
  loading: false,
  user: {}
})

const CardsDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [dadosCard, setDadosCard] = useState<CardsData>(defautCardsData);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState<any>(null);
  useEffect(() => {
    setLoading(true)
    getUsuario().then((result)=>{
      setUser(result?.data)
  
    })
    getCardDatas().then((result) => {
      setDadosCard(result)
      setLoading(false)
    }).catch((e) => {
      setError(e?.message)
      console.warn(e?.message)
      setLoading(false)
    })

  }, [])

  return (
    <CardsDataContext.Provider value={{ dadosCard, error, loading, user }}>
      {children}
    </CardsDataContext.Provider>
  );
};
export default CardsDataProvider;