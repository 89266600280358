import React, { createContext, useEffect, useState } from 'react';
import { getListMain } from '../../../api/api';
import { HomeObjet } from '../../../types/types';
import { useRecoilState } from 'recoil';
import { agendamentoDataFinal, agendamentoDataInicial, atualizacaoDataFinal, atualizacaoDataInicial, codigoRejeicao, contratoAtom, dataAgendamento, dataAtualizacao, dataErroLogEnd, dataErroLogStart, dataOcorrenciaEnd, dataOcorrenciaStart, dataPendenciaEnd, dataPendenciaStart, erroLog, especieAtom, modeloDocumento, pendenciaConciliacao, pracaAtom, rejeicaoFiscal, situacaoModulo, statusAtualizacaoAtom, statusExecucao, versaoEmUsoAtom } from '../../../atom/formFiltroPrincipal';
interface DadosCardContextProps {
  dadosHome: HomeObjet[],
  error: Error | null | unknown,
  loading: boolean,
  currentPage: number,
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
  limite: number,
  setLimite: React.Dispatch<React.SetStateAction<number>>,
  setDadoHome: React.Dispatch<React.SetStateAction<HomeObjet[]>>,
  setTotal: React.Dispatch<React.SetStateAction<number>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  total: number
}
const defautListData: HomeObjet[] = []
export const ListMainContext = createContext<DadosCardContextProps>({
  dadosHome: defautListData,
  error: null,
  loading: false,
  currentPage: 1,
  limite: 10,
  setCurrentPage: () =>{},
  setLimite: () => {},
  setDadoHome: () => {},
  setTotal: () => {},
  setLoading: () => {},
  total: 0
})

const DadosListProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [dadosHome, setDadoHome] = useState<HomeObjet[]>(defautListData);
  const [currentPage, setCurrentPage] = useState(1);
  const [limite, setLimite] = useState(10)

  const [contrato] = useRecoilState(contratoAtom);
  const [versaoEmUso] = useRecoilState(versaoEmUsoAtom);
  const [stExecucao] = useRecoilState(statusExecucao);
  const [statusAualizacao] = useRecoilState(statusAtualizacaoAtom);
  const [dataAgendada] = useRecoilState(dataAgendamento);
  const [dtAtualizado] = useRecoilState(dataAtualizacao);
  const [praca] = useRecoilState(pracaAtom);


  const [textErroLog] = useRecoilState(erroLog);
  const [textPendenciaConciliacao] =
    useRecoilState(pendenciaConciliacao);
  const [textRejeicoesFiscais] =
    useRecoilState(rejeicaoFiscal);
    const [textCodigoRejeicao] =
    useRecoilState(codigoRejeicao);
    const [textModeloDocumento] =
    useRecoilState(modeloDocumento);
    const [textDataOcorrenciaStart] =
    useRecoilState(dataOcorrenciaStart);
    const [textDataOcorrenciaFinal] =
    useRecoilState(dataOcorrenciaEnd);

    const [textAgendamentoDataInicial] =
    useRecoilState(agendamentoDataInicial);
  const [textAgendamentoDataFinal] =
    useRecoilState(agendamentoDataFinal);

    const [textAtualizacaoDataInicial] =
    useRecoilState(atualizacaoDataInicial);
  const [textAtualizacaoDataFinal] =
    useRecoilState(atualizacaoDataFinal);

    const [textDataErroLogStart] =
    useRecoilState(dataErroLogStart);
  const [textDataErroLogEnd] =
    useRecoilState(dataErroLogEnd);

    const [textDataPendenciaStart] =
    useRecoilState(dataPendenciaStart);
  const [textDataPendenciaEnd] =
    useRecoilState(dataPendenciaEnd);

  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0);

  const [textEspecie, setTextEspecie] = useRecoilState(especieAtom);
  const [textSituacaoModulo, setTextSituacaoModulo] =
    useRecoilState(situacaoModulo);



    useEffect(() => {
      setLoading(true)
      getListMain(-1, 10, contrato, versaoEmUso,
        textAgendamentoDataInicial, textAgendamentoDataFinal,
        textAtualizacaoDataInicial, textAtualizacaoDataFinal, textDataOcorrenciaStart, textDataOcorrenciaFinal, textCodigoRejeicao,
        textModeloDocumento, textRejeicoesFiscais, textPendenciaConciliacao, textErroLog, praca,
        textEspecie, textSituacaoModulo).then((result) => {
        setDadoHome(result?.items)
        setTotal(result?.totalElements)
        setLoading(false)
      }).catch((e) => {
        setError(e?.message)
        console.warn(e?.message)
        setLoading(false)
      })
    
    }, [contrato, versaoEmUso, stExecucao, statusAualizacao, total, dataAgendada, dtAtualizado, textDataOcorrenciaStart, textDataOcorrenciaFinal, textCodigoRejeicao, textModeloDocumento, textRejeicoesFiscais, textPendenciaConciliacao, textErroLog, textAgendamentoDataInicial, textAgendamentoDataFinal, textAtualizacaoDataInicial, textAtualizacaoDataFinal, textDataErroLogStart, textDataErroLogEnd, textDataPendenciaStart, textDataPendenciaEnd, praca,
    textEspecie, textSituacaoModulo])
    

  return (
    <ListMainContext.Provider value={{ dadosHome, error, loading, currentPage, limite, setCurrentPage, setLimite,setDadoHome,setTotal,setLoading, total}}>
      {children}
    </ListMainContext.Provider>
  );
};
export default DadosListProvider;