import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import {
  Alert,
  DatePicker,
  Pagination,
  PaginationProps,
  Table,
  Tag,
  Typography,
  Input,
  Select,
  Tooltip
} from "antd";

import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles.css";
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { SimpleGrid } from "@chakra-ui/react";
import { CardHome } from "./CardHome";

//import type { ColumnsType } from "antd/es/table";
import { CardsDataContext } from "./context/DadosCardContext";
import { ListMainContext } from "./context/ListMainContext";
import { HomeObjet } from "../../types/types";
import type { TableProps } from 'antd';
import {
  isMatriz,
  reduceText,
  rowClassName,
  stringToDateWithHour,
} from "../../util/util";
import { useDrawerModalFiltroPrincipal } from "./useDrawerModalFiltroPrincipal";
import { atom, useRecoilState } from "recoil";
import { contratoAtom, pracaAtom, versaoEmUsoAtom, especieAtom, situacaoModulo,atualizacaoDataInicial,atualizacaoDataFinal } from "../../atom/formFiltroPrincipal";
import { nomeUsuario } from "../../atom/usuario";
import type { ColumnsType, FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import moment from "moment";
import { getListMain } from "../../api/api";

const { Text, Title, Link } = Typography;
const { Option } = Select;
const data = [
  {
    name: "Sistemas em Uso",
    uv: 4000,
    pv: 12,
    amt: 2400,
  },
  {
    name: "Aguardando Atualização",
    uv: 3000,
    pv: 83,
    amt: 2210,
  },
  {
    name: "Sistema com Erro de Logs",
    uv: 2000,
    pv: 45,
    amt: 2290,
  },
  {
    name: "Sistema com Conciliação pendente",
    uv: 2780,
    pv: 23,
    amt: 2000,
  },
  {
    name: "Sistema com Rejeições Fiscais",
    uv: 1890,
    pv: 12,
    amt: 2181,
  },
];

interface DataType {
  id: string;
  contrato: string;
  codigo_versao: number;
  status_execucao: string;
  data_agendada: string;
  data_atualizacao: string;
  status: string;
  erros_log: number;
  rejeicoes_fiscais: number;
  pendencia_conciliacao: number;
}

const { RangePicker } = DatePicker;
const { Search } = Input;

export const PrincipalPage = () => {
  const { dadosCard, error, loading, user } = useContext(CardsDataContext);
  const [nameUser, setNameUser] = useRecoilState(nomeUsuario);


  const [openDrawer, setOpenDrawer] = useState(false);
  const [atualizarFetch, setAtualizarFetch] = useState(false);
  const [textContrato, setTextContrato] = useRecoilState(contratoAtom);
  const [textVersao, setTextVersao] = useRecoilState(versaoEmUsoAtom);
  const [textEspecie, setTextEspecie] = useRecoilState(especieAtom);
  const [textSituacaoModulo, setTextSituacaoModulo] = useRecoilState(situacaoModulo);  
  const [textAtualizacaoDataInicial, setTextAtualizacaoDataInicial] = useRecoilState(atualizacaoDataInicial);  
  const [textAtualizacaoDataFinal, setTextAtualizacaoDataFinal] = useRecoilState(atualizacaoDataFinal);  

  
  const [textPraca, setTextPraca] = useRecoilState(pracaAtom);
  const [filtroContrato, setFiltroContrato] = useState('');
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({});
  const [allData, setAllData] = useState<HomeObjet[]>([]);


  const {
    contextHolderDrawerCreateVersion,
    showDrawerCriarModal,
    DrawerModal,
    textContrato: te,
  } = useDrawerModalFiltroPrincipal(
    setAtualizarFetch,
    atualizarFetch,
    openDrawer,
    setOpenDrawer
  );

  const {
    dadosHome,
    error: errorList,
    loading: loadingList,
    currentPage,
    limite,
    setCurrentPage,
    setLimite,
    setDadoHome,
    setTotal,
    setLoading,
    total,
  } = useContext(ListMainContext);

  useEffect(() => {
    setNameUser("dddsds");
    if (!loadingList) {
      setOpenDrawer(false)
    }
    //getUsuario();
  }, [setLimite, user]);

  console.log(textContrato)
  
  // const paginatedData = useMemo(() => {
  //   let filteredData = [...dadosHome];
  
  //   // Aplicar filtro por contrato se houver texto de busca
  //   if (filtroContrato) {
  //     filteredData = filteredData.filter(item => item.contrato.toLowerCase().includes(filtroContrato.toLowerCase()));
  //   }
  
  //   // Aplicar a ordenação apenas se houver uma coluna selecionada para ordenar
  //   if (sortedInfo.columnKey) {
  //     filteredData = filteredData.sort((a: HomeObjet, b: HomeObjet) => {
  //       const getColumnValue = (item: HomeObjet): any => {
  //         switch (sortedInfo.columnKey) {
  //           case 'contrato':
  //             return item.contrato;
  //           case 'dataAtualizacao':
  //             return moment(item.dataAtualizacao);
  //           case 'ultimo_dia_trabalhando':
  //             return moment(item.ultimoDiaTrabalhado);
  //           // Adicione mais casos conforme necessário para outras colunas
  //           default:
  //             return null;
  //         }
  //       };
  
  //       const valueA = getColumnValue(a);
  //       const valueB = getColumnValue(b);
  
  //       if (moment.isMoment(valueA) && moment.isMoment(valueB)) {
  //         return sortedInfo.order === 'ascend' ? valueA.diff(valueB) : valueB.diff(valueA);
  //       } else if (typeof valueA === 'string' && typeof valueB === 'string') {
  //         return sortedInfo.order === 'ascend' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
  //       }
  
  //       return 0;
  //     });
  //   }
  
  //   // Calcula os índices de início e fim para a página atual
  //   const startIndex = (currentPage - 1) * limite;
  //   const endIndex = Math.min(startIndex + limite, filteredData.length);
  
  //   // Retorna os dados paginados
  //   return filteredData.slice(startIndex, endIndex);
  // }, [dadosHome, currentPage, limite, sortedInfo, filtroContrato]);
  


  // @ts-ignore
  const handleChange: TableProps<DataType>['onChange'] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<HomeObjet> | SorterResult<HomeObjet>[]) => {
    console.log('Various parameters', pagination, filters, sorter);
    //setFilteredInfo(filters);
    //setSortedInfo(sorter as SorterResult<DataType>);
  };


  const columns: ColumnsType<HomeObjet> = [
    {
      title: "Contrato",
      dataIndex: "contrato",
      key: "contrato",
      render: (text: string) => <a>{text}</a>,
      fixed: 'left',
      showSorterTooltip: false,
      sorter: (a: HomeObjet, b: HomeObjet) => Number(a.contrato) - Number(b.contrato),
      sortOrder: sortedInfo.columnKey === 'contrato' ? sortedInfo.order : null,
      className: 'contrato-column',
    },
    {
      title: "Razão social",
      dataIndex: "razaoSocial",
      key: "razaoSocial",
      width: "310px",
      fixed: 'left',
      filteredValue: filteredInfo.address || null,
      onFilter: (value, record) => record.razaoSocial.toString().includes(value.toString()),
      sorter: (a, b) => a.razaoSocial.localeCompare(b.razaoSocial),
      sortOrder: sortedInfo.columnKey === 'razaoSocial' ? sortedInfo.order : null,
      showSorterTooltip: false,
      render: (text: string) => {
        return (
          <>
            <Tooltip title={text}>
              <a>{reduceText(text, 30)}</a>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Tipo Instalação",
      dataIndex: "tipoInstalacao",
      align: "left",
      key: "tipoInstalacao",
      render: (text: string) => {
        let status = "";
        let color = "";
        if (Number(text) === 1) {
          status = "1 - Venda";
          color = "#bfbfbf";
        }
        if (Number(text) === 2) {
          status = "2 - Atualização Gratuita";
          color = "#bfbfbf";
        }
        if (Number(text) === 3) {
          status = "3 - Alteração";
          color = "#bfbfbf";
        }
        if (Number(text) === 4) {
          status = "4 - Comodato";
          color = "#bfbfbf";
        }
        if (Number(text) === 5) {
          status = "5 - Demonstração";
          color = "#ff4d4f";
        }
        if (Number(text) === 6) {
          status = "6 - Reativação";
          color = "#bfbfbf";
        }
        if (text) {
          if (Number(text) === 5) {
            return (
              <Tooltip title={status}>
                <Tag style={{ width: "100px", textAlign: "left", color: "black", fontWeight: 'semibold' }} color={color}>
                  {reduceText(status, 14)}
                </Tag>
              </Tooltip>

            );
          } else {
            return (
              <Tooltip title={status}>
                <Tag style={{ width: "100px", textAlign: "left", color: "black" }} color={color}>
                  {reduceText(status, 14)}
                </Tag>
              </Tooltip>

            );
          }
        } else {
          <></>;
        }
      },
    },
    {
      title: "Situação Módulo",
      dataIndex: "situacaoModulo",
      key: "situacaoModulo",

      render: (text: string) => {
        let status = "";
        let color = "";
        if (Number(text) === 1) {
          status = "1 - Cliente não cadastrado";
          color = "#bfbfbf";
        }
        if (Number(text) === 2) {
          status = "2 - Ficha Bloqueada";
          color = "#bfbfbf";
        }
        if (Number(text) === 3) {
          status = "3 - Manutenção não Cadastrada";
          color = "#bfbfbf";
        }
        if (Number(text) === 4) {
          status = "4 - Manutenção c/Impedimento";
          color = "#bfbfbf";
        }
        if (Number(text) === 5) {
          status = "5 - Divergência Valor Manutenção";
          color = "#bfbfbf";
        }

        if (text) {
          return (
            <Tooltip title={status}>
              <Tag style={{ textAlign: "left", color: "black" }} color={color}>
                {reduceText(status, 14)}
              </Tag>
            </Tooltip>

          );
        } else {
          <></>;
        }
      },
    },
    {
      title: "Situação Contrato",
      dataIndex: "situacaoContrato",
      key: "situacaoContrato",

      render: (text: string) => {
        let status = "";
        let color = "";
        if (text === 'B') {
          status = "Bloqueado";
          color = "#bfbfbf";
        }
        if (text === 'D') {
          status = "Desbloqueado";
          color = "#bfbfbf";
        }
        if (text === 'S') {
          status = "Suspenso";
          color = "#bfbfbf";
        }
        if (text === 'P') {
          status = "Pendente";
          color = "#bfbfbf";
        }
        if (text === 'V') {
          status = "Vencido";
          color = "#bfbfbf";
        }
        if (text === 'C') {
          status = "Cancelado";
          color = "#bfbfbf";
        }

        if (text) {
          return (
            <Tooltip title={status}>
              <Tag style={{ textAlign: "left", color: "black" }} color={color}>
                {reduceText(status, 14)}
              </Tag>
            </Tooltip>

          );
        } else {
          <></>;
        }
      },
    },
    {
      title: "Qtd. Empresas",
      dataIndex: "quantidadeEmpresas",
      key: "quantidadeEmpresas",
      render: (value) => {
        if (value) {
          if (Number(value) > 0) {
            return value
          }
        }
      }
    },
    // {
    //   title: "Empresa",
    //   dataIndex: "empresa",
    //   key: "empresa",
    // },
    {
      title: "Versão",
      dataIndex: "versao",
      key: "data_atcodigo_versaoualizacao",
    },
    {
      title: "Data atualização",
      dataIndex: "dataAtualizacao",
      key: "dataAtualizacao",
      render: (text: string) => {
        if (text) {
          return <a>{stringToDateWithHour(text)}</a>;
        }
      },
      sorter: (a, b) => {
        if (a.dataAtualizacao && b.dataAtualizacao) {
          return new Date(a.dataAtualizacao).getTime() - new Date(b.dataAtualizacao).getTime();
        }
        return 0;
      },
      sortOrder: sortedInfo.columnKey === 'dataAtualizacao' ? sortedInfo.order : null,
    },

    {
      title: "Último Dia Trabalhado",
      dataIndex: "ultimoDiaTrabalhado",
      key: "ultimoDiaTrabalhado",
      render: (text: string) => {
        if (text) {
          return <a>{stringToDateWithHour(text)}</a>;
        }
      },
      sorter: (a, b) => {
        if (a.ultimoDiaTrabalhado && b.ultimoDiaTrabalhado) {
          return new Date(a.ultimoDiaTrabalhado).getTime() - new Date(b.ultimoDiaTrabalhado).getTime();
        }
        return 0;
      },
      sortOrder: sortedInfo.columnKey === 'ultimoDiaTrabalhado' ? sortedInfo.order : null,
    },
    {
      title: "Erros de Log",
      dataIndex: "quantidadeErrosLog",
      key: "quantidadeErrosLog",
      render: (text: string, object: HomeObjet) => (
        <a
          onClick={() => {
            openNewWindow("/painel/colaborador/erro-logs/" + object?.contrato);
            //navigate("/painel/colaborador/erro-logs/" + object?.contrato);
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Rejeições Fiscais",
      dataIndex: "rejeicoes_fiscais",
      key: "rejeicoes_fiscais",
      render: (text: string, object: HomeObjet) => (
        <a
          onClick={() => {
            openNewWindow(
              "/painel/colaborador/rejeicoes-fiscais/" + object?.contrato
            );
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Pendência de Conciliação",
      dataIndex: "pendencias_conciliacao",
      key: "pendencias_conciliacao",
      render: (text: string, object: HomeObjet) => (
        <a
          onClick={() => {
            openNewWindow(
              "/painel/colaborador/pendencia-conciliacao/" + object?.contrato
            );
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Status de Atualização",
      dataIndex: "status_atualizacao",
      key: "status_atualizacao",
      render: (text: string) => {
        let status = "";
        let color = "";
        if (Number(text) === 1) {
          status = "Atualizado";
          color = "#87d068";
        }
        if (Number(text) === 2) {
          status = "Ag. Atualização";
          color = "#d0cb68";
        }
        if (Number(text) === 3) {
          status = "Agu. Intervenção";
          color = "#d09368";
        }
        if (Number(text) === 4) {
          status = "Desatualizado";
          color = "#d06868";
        }
        if (text) {
          return (
            <Tag style={{ width: "100px", textAlign: "center" }} color={color}>
              {status}
            </Tag>
          );
        } else {
          <></>;
        }
      },
    },
    {
      title: "Status de Execução",
      dataIndex: "status_execucao",
      key: "status_execucao",
      render: (text: string) => {
        let status = "";
        let color = "";
        if (Number(text) === 1) {
          status = "Manual";
          color = "#d0cb68";
        }
        if (Number(text) === 2) {
          status = "Automático";
          color = "#87d068";
        }
        if (text) {
          return (
            <Tag style={{ width: "100px", textAlign: "center" }} color={color}>
              {status}
            </Tag>
          );
        }
      },
    },
    {
      title: "Data Agendada",
      dataIndex: "dt_agendada",
      key: "data_agendada",
      render: (text: string) => {
        if (text) {
          return <a>{stringToDateWithHour(text)}</a>;
        }
      },
    },
    {
      title: "Histórico",
      dataIndex: "Histórico",
      key: "Histórico",
      fixed: 'right',
      render: (_text: string, object: HomeObjet) => (
        <a
          onClick={() => {
            openNewWindow(
              "/painel/colaborador/historico/" + object?.contrato
            );
          }}
        >
          {"Abrir"}
        </a>
      ),
    },
  ];
  const onChange: PaginationProps["onChange"] = (page, limit) => {
    console.log(page);
    setCurrentPage(page);
    setLimite(limit)

    fetchData(page, limit); // Função que busca os dados da API

  };

  const fetchData = async (page: number, size: number) => {
    setLoading(true);
    
    // Faça a requisição para a API com a página e o limite como parâmetros
    const response = getListMain(page, size, textContrato, textVersao,
      '', '',
      textAtualizacaoDataInicial, textAtualizacaoDataFinal, '', '', '',
      '', '', '', '', textPraca,
      textEspecie, textSituacaoModulo).then((result) => {
      // setDadoHome(result?.controleAtualizacaoDTOList)      
      setDadoHome(result.items);
      setTotal(result?.totalElements)
      //setLimite(result.totalElements);
      setLoading(false)
      
    }).catch((e) => {
      //setError(e?.message)
      console.warn(e?.message)
      setLoading(false)
    })
      
    
    
    //setLoadingList(false);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const navigate = useNavigate();

  const openNewWindow = (uri: string) => {
    window.open(uri, "_blank");
  };

  return (
    <>
      {contextHolderDrawerCreateVersion}
      <VStack w={"full"}>
        <VStack bgColor={"#F7FAFC"} borderRadius={0} minH={400} w={"full"}>
          <VStack px={1} alignItems={"start"} w="full">
            <HStack pt={1}>
            <Input addonAfter={<SearchOutlined />}
                placeholder="Busque por contrato"
                allowClear
                onChange={(e) => {
                  setTextContrato(e.target.value);
                }}
              />
              {!isMatriz(user?.praca) && <VStack w={10}></VStack>}
              {isMatriz(user?.praca) && (
                <VStack alignItems={"start"} w={"full"}>
                  <VStack w={200}>
                    <Select
                      style={{ width: "100%" }}
                      value={textPraca}
                      onChange={(e) => {
                        setTextPraca(e);
                      }}
                      placeholder="Por favor insira a praça desejada"
                    >
                      <Option value="MA000031">Praça</Option>
                      <Option value="MA000001">PARACATU</Option>
                      <Option value="MA000003">UNAI</Option>
                      <Option value="MA000004">BRASILIA</Option>
                      <Option value="MA000005">BRASILANDIA DE MINAS</Option>
                      <Option value="MA000006">JOÃO PINHEIRO</Option>
                      <Option value="MA000008">ARAXA</Option>
                      <Option value="MA000009">VAZANTE</Option>
                      <Option value="MA000013">CRISTALINA</Option>
                      <Option value="MA000028">TRÊS MARIAS</Option>
                      <Option value="MA000031">MATRIZ</Option>
                    </Select>
                  </VStack>
                </VStack>
              )}

              <VStack w={50}>
                <Button
                  size={"md"}
                  mx={10}
                  onClick={showDrawerCriarModal}
                  leftIcon={<FilterOutlined />}
                  colorScheme={"blue"}
                >
                  Filtro
                </Button>
              </VStack>
            </HStack>
          </VStack>
          <VStack w={"full"}>
            {error ? (
              <Alert
                style={{ width: "100%" }}
                message="Error"
                description={JSON.stringify(error)}
                type="error"
                showIcon
              />
            ) : (
              <SimpleGrid
                columns={{ sm: 1, md: 2, lg: 5 }}
                spacing={3}
                w="full"
                py={2}
              >
                <CardHome
                  color="green"
                  titulo={"Sistema em Uso"}
                  valor={dadosCard?.sistemaUso}
                  error={error}
                  loading={loading}
                />
                <CardHome
                  color="blue"
                  titulo={"Aguard. Atualização"}
                  valor={dadosCard?.aguardandoAtualizacao}
                  error={error}
                  loading={loading}
                />
                <CardHome
                  color="blue"
                  titulo={"Rejeições Fiscais"}
                  valor={dadosCard?.rejeicaoFiscal}
                  error={error}
                  loading={loading}
                />
                <CardHome
                  color="blue"
                  titulo={"Pend. Conciliação"}
                  valor={dadosCard?.conciliacaoPendente}
                  error={error}
                  loading={loading}
                />
                <CardHome
                  color="blue"
                  titulo={"Erros de Log"}
                  valor={dadosCard?.errosLog}
                  error={error}
                  loading={loading}
                />
              </SimpleGrid>
            )}
          </VStack>
          <VStack width={"100%"} overflowX={"auto"}>
            <Table
              loading={loadingList}
              rowClassName={rowClassName}
              size="small"
              // @ts-ignore
              onChange={handleChange}
              pagination={false}
              scroll={{ x: 2300 }}
              columns={columns}
              dataSource={dadosHome}
            />
          </VStack>

          <VStack my={4}>
            <Pagination
              total={total}
              showTotal={(total) => `Total de ${total} itens`}
              defaultPageSize={limite}
              defaultCurrent={1}
              onChange={onChange}
              current={currentPage}
              locale={{ items_per_page: "/ Página" }}
            />
          </VStack>
        </VStack>
      </VStack>
      <Modal
        size={"lg"}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Criar versão de produção</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack overflow={"auto"} maxH={300}>
              <FormControl>
                <FormLabel>Título</FormLabel>
                <Input ref={initialRef} placeholder="Versão x-yz" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Versão</FormLabel>
                <Input placeholder="Last name" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Descrição</FormLabel>
                <Input placeholder="Last name" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Nome do arquivo</FormLabel>
                <Input placeholder="Last name" />
              </FormControl>
              <FormControl>
                <FormLabel>Descrição</FormLabel>
                <Textarea placeholder="Descreva a versão" size="sm" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>
                  Por favor, digite "eu confirmo que desejo lançar essa versão"
                </FormLabel>
                <Input placeholder="" />
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3}>
              Lançar
            </Button>
            <Button onClick={onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenFilter} onClose={onCloseFilter}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>First name</FormLabel>
              <Input ref={initialRef} placeholder="First name" />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Last name</FormLabel>
              <Input placeholder="Last name" />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {DrawerModal}
    </>
  );
};
