import { atom } from "recoil";

export const contratoErrosGeraisAtom = atom({
    key: 'contratoAtomLog',
    default: '',
  });
  export const codigoVersaoErrosGeraisAtom = atom({
    key: 'codigoVersaoErrosGeraisAtomLog',
    default: '',
  });
  export const descricaoErrosGeraisAtom = atom({
    key: 'descricaoErrosGeraisAtomLog',
    default: '',
  });
  export const pracaErrosGeraisAtom = atom({
    key: 'pracaErrosGeraisAtomLog',
    default: '',
  });
  export const programaErrosGeraisAtom = atom({
    key: 'programaErrosGeraisAtomLog',
    default: '',
  });
  export const dataAtualizacao = atom({
    key: 'dataAtualizacaoLog',
    default: '',
  });
  export const erroLog = atom({
    key: 'erroLogAtom',
    default: '0',
  });

  export const dataOcorrenciaStart = atom({
    key: 'dataOcorrenciaStartLog',
    default: '',
  });
  export const dataOcorrenciaEnd = atom({
    key: 'dataOcorrenciaEndLog',
    default: '',
  });
  export const ocorrenciaDataInicialErrosGerais = atom({
    key: 'ocorrenciaDataInicialErrosGeraisLog',
    default: '',
  });
  export const ocorrenciaDataFinalErrosGerais = atom({
    key: 'ocorrenciaDataFinalErrosGeraisLog',
    default: '',
  });

  export const dataExecDataInicialErrosGerais = atom({
    key: 'dataExecDataInicialErrosGeraisLog',
    default: '',
  });
  export const dataExecDataFinalErrosGerais = atom({
    key: 'dataExecDataFinalErrosGeraisLog',
    default: '',
  });

  export const atualizacaoDataInicial = atom({
    key: 'atualizacaoDataInicialLog',
    default: '',
  });
  export const atualizacaoDataFinal = atom({
    key: 'atualizacaoDataFinalLog',
    default: '',
  });
