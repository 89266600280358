import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import {
  Alert,
  DatePicker,
  Pagination,
  PaginationProps,
  Table,
  Tag,
  Typography,
  Input,
  Select,
  Tooltip
} from "antd";

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles.css";
import { FilterOutlined } from "@ant-design/icons";
import { SimpleGrid } from "@chakra-ui/react";
import { CardHome } from "./CardHome";

import type { ColumnsType } from "antd/es/table";
import { getCardDatas } from "../../api/api";
import { CardsDataContext } from "./context/DadosCardContext";
import { ListMainContext } from "./context/ListMainContext";
import { HomeObjet } from "../../types/types";
import {
  isMatriz,
  reduceText,
  rowClassName,
  stringToDateWithHour,
} from "../../util/util";
import { Path } from "./Path";
import { useDrawerModalFiltroPrincipal } from "./useDrawerModalFiltroPrincipal";
import { atom, useRecoilState } from "recoil";
import { contratoAtom, pracaAtom, versaoEmUsoAtom } from "../../atom/formFiltroPrincipal";
import { nomeUsuario } from "../../atom/usuario";
import { useDrawerModalFiltroHistorico } from "./useDrawerModalFiltroHistorico";
import { ListHistoricoContext } from "./context/ListHistoricoContext";
const { Text, Title, Link } = Typography;
const { Option } = Select;
const data = [
  {
    name: "Sistema em Uso",
    uv: 4000,
    pv: 12,
    amt: 2400,
  },
  {
    name: "Aguardando Atualização",
    uv: 3000,
    pv: 83,
    amt: 2210,
  },
  {
    name: "Sistema com Erro de Logs",
    uv: 2000,
    pv: 45,
    amt: 2290,
  },
  {
    name: "Sistema com Conciliação pendente",
    uv: 2780,
    pv: 23,
    amt: 2000,
  },
  {
    name: "Sistema com Rejeições Fiscais",
    uv: 1890,
    pv: 12,
    amt: 2181,
  },
];

const getIntroOfPage = (label: any) => {
  if (label === "Page A") {
    return "Page A is about men's clothing";
  }
  if (label === "Page B") {
    return "Page B is about women's dress";
  }
  if (label === "Page C") {
    return "Page C is about women's bag";
  }
  if (label === "Page D") {
    return "Page D is about household goods";
  }
  if (label === "Page E") {
    return "Page E is about food";
  }
  if (label === "Page F") {
    return "Page F is about baby food";
  }
  return "";
};
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
        <p className="intro">{getIntroOfPage(label)}</p>
      </div>
    );
  }

  return null;
};

interface DataType {
  id: string;
  contrato: string;
  codigo_versao: number;
  status_execucao: string;
  data_agendada: string;
  data_atualizacao: string;
  status: string;
  erros_log: number;
  rejeicoes_fiscais: number;
  pendencia_conciliacao: number;
}

const { RangePicker } = DatePicker;
const { Search } = Input;

export const HistoricoListagemPage = () => {
  const { dadosCard, error, loading, user } = useContext(CardsDataContext);
  const [nameUser, setNameUser] = useRecoilState(nomeUsuario);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [atualizarFetch, setAtualizarFetch] = useState(false);
  const [textContrato, setTextContrato] = useRecoilState(contratoAtom);
  const [textVersaoEmUso, setTextVersaoEmUso] = useRecoilState(versaoEmUsoAtom);
  const [textPraca, setTextPraca] = useRecoilState(pracaAtom);
  const { contrato } = useParams();


  const {
    contextHolderDrawerCreateVersion,
    showDrawerCriarModal,
    DrawerModal,
    textContrato: te,
  } = useDrawerModalFiltroHistorico(
    setAtualizarFetch,
    atualizarFetch,
    openDrawer,
    setOpenDrawer
  );
  const {
    dadosHome,
    error: errorList,
    loading: loadingList,
    currentPage,
    setCurrentPage,
    limite,
    setLimite,
    total,
  } = useContext(ListHistoricoContext);
  const [loadingUser, setLoadingUser] = useState(false);

  useEffect(() => {
    setLimite(10);
    setNameUser("dddsds");
    //getUsuario();
  }, [setLimite, user]);
  const navegar = useNavigate();

  const columns: ColumnsType<HomeObjet> = [
    {
      title: "Contrato",
      dataIndex: "contrato",
      className: 'contrato-column',
      key: "contrato",
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: "Razão Social",
      dataIndex: "razaoSocial",
      key: "razaoSocial",
      width: "280px",
      render: (text: string) => {
        return (
          <>
            <Tooltip title={text}>
              <a>{reduceText(text, 35)}</a>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Tipo Instalação",
      dataIndex: "tipoInstalacao",
      key: "tipoInstalacao",
      render: (text: string) => {
        let status = "";
        let color = "";
        if (Number(text) === 1) {
          status = "1 - Venda";
          color = "#bfbfbf";
        }
        if (Number(text) === 2) {
          status = "2 - Atualização Gratuita";
          color = "#bfbfbf";
        }
        if (Number(text) === 3) {
          status = "3 - Alteração";
          color = "#bfbfbf";
        }
        if (Number(text) === 4) {
          status = "4 - Comodato";
          color = "#bfbfbf";
        }
        if (Number(text) === 5) {
          status = "5 - Demonstração";
          color = "#ff4d4f";
        }
        if (Number(text) === 6) {
          status = "6 - Reativação";
          color = "#bfbfbf";
        }
        if (text) {
          if(Number(text) === 5){
            return (
              <Tooltip title={status}>
              <Tag style={{ width: "100px", textAlign: "center", color: "black", fontWeight: 'semibold'}} color={color}>
              {reduceText(status, 14)}
              </Tag>
            </Tooltip>
             
            );
          }else{
            return (
              <Tooltip title={status}>
              <Tag style={{ width: "100px", textAlign: "center", color: "black" }} color={color}>
              {reduceText(status, 14)}
              </Tag>
            </Tooltip>
             
            );
          }
        } else {
          <></>;
        }
      },
    },
    {
      title: "Situação Módulo",
      dataIndex: "situacaoModulo",
      key: "situacaoModulo",
     
      render: (text: string) => {
        let status = "";
        let color = "";
        if (Number(text) === 1) {
          status = "1 - Cliente não cadastrado";
          color = "#bfbfbf";
        }
        if (Number(text) === 2) {
          status = "2 - Ficha Bloqueada";
          color = "#bfbfbf";
        }
        if (Number(text) === 3) {
          status = "3 - Manutenção não Cadastrada";
          color = "#bfbfbf";
        }
        if (Number(text) === 4) {
          status = "4 - Manutenção c/Impedimento";
          color = "#bfbfbf";
        }
        if (Number(text) === 5) {
          status = "5 - Divergência Valor Manutenção";
          color = "#bfbfbf";
        }

        if (text) {
          return (
            <Tooltip title={status}>
            <Tag style={{ textAlign: "center", color: "black" }} color={color}>
            {reduceText(status, 14)}
            </Tag>
          </Tooltip>
           
          );
        } else {
          <></>;
        }
      },
    },  
    {
      title: "Código De Versão",
      dataIndex: "versao",
      key: "versao",
    },
    
    {
      title: "Data Atualização",
      dataIndex: "dataAtualizacao",
      key: "dataAtualizacao",
      render: (text: string) => {
        if (text) {
          return <a>{stringToDateWithHour(text)}</a>;
        }
      },
    },
    {
      title: "Status De Atualização",
      dataIndex: "status_atualizacao",
      key: "status_atualizacao",
      render: (text: string) => {
        let status = "";
        let color = "";
        if (Number(text) === 1) {
          status = "Atualizado";
          color = "#87d068";
        }
        if (Number(text) === 2) {
          status = "Ag. Atualização";
          color = "#d0cb68";
        }
        if (Number(text) === 3) {
          status = "Agu. Intervenção";
          color = "#d09368";
        }
        if (Number(text) === 4) {
          status = "Desatualizado";
          color = "#d06868";
        }
        if (text) {
          return (
            <Tag style={{ width: "100px", textAlign: "center" }} color={color}>
              {status}
            </Tag>
          );
        } else {
          <></>;
        }
      },
    },
    {
      title: "Status de Execução",
      dataIndex: "status_execucao",
      key: "status_execucao",
      render: (text: string) => {
        let status = "";
        let color = "";
        if (Number(text) === 1) {
          status = "Manual";
          color = "#d0cb68";
        }
        if (Number(text) === 2) {
          status = "Automático";
          color = "#87d068";
        }
        if (text) {
          return (
            <Tag style={{ width: "100px", textAlign: "center" }} color={color}>
              {status}
            </Tag>
          );
        }
      },
    },
    {
      title: "Data Agendada",
      dataIndex: "dt_agendada",
      key: "data_agendada",
      render: (text: string) => {
        if (text) {
          return <a>{stringToDateWithHour(text)}</a>;
        }
      },
    },
  ];
  const onChange: PaginationProps["onChange"] = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const navigate = useNavigate();

  const openNewWindow = (uri: string) => {
    window.open(uri, "_blank");
  };

  return (
    <>
      {contextHolderDrawerCreateVersion}
      <VStack w={"full"}>
     
        <VStack bgColor={"#F7FAFC"} borderRadius={0} minH={400} w={"full"}>
          
          <VStack px={1} alignItems={"start"} w="full">
          <Title level={3}>
          Histórico de Atualização ({contrato})
        </Title>
            <HStack pt={1}>
              <Search
                placeholder="Busque por Versão"
                allowClear
                onChange={(e) => {
                  setTextVersaoEmUso(e.target.value);
                }}
              />
              {!isMatriz(user?.praca) && <VStack w={10}></VStack>}
              {isMatriz(user?.praca) && (
                <VStack alignItems={"start"} w={"full"}>
                  <VStack w={200}>
                    <Select
                      style={{ width: "100%" }}
                      value={textPraca}
                      onChange={(e) => {
                        setTextPraca(e);
                      }}
                      placeholder="Por favor insira a praça desejada"
                    >
                      <Option value="MA000031">Praça</Option>
                      <Option value="MA000001">PARACATU</Option>
                      <Option value="MA000003">UNAI</Option>
                      <Option value="MA000004">BRASILIA</Option>
                      <Option value="MA000005">BRASILANDIA DE MINAS</Option>
                      <Option value="MA000006">JOÃO PINHEIRO</Option>
                      <Option value="MA000008">ARAXA</Option>
                      <Option value="MA000009">VAZANTE</Option>
                      <Option value="MA000013">CRISTALINA</Option>
                      <Option value="MA000028">TRÊS MARIAS</Option>
                      <Option value="MA000031">MATRIZ</Option>
                    </Select>
                  </VStack>
                </VStack>
              )}

              <VStack w={50}>
                <Button
                  size={"md"}
                  mx={10}
                  onClick={showDrawerCriarModal}
                  leftIcon={<FilterOutlined />}
                  colorScheme={"blue"}
                >
                  Filtro
                </Button>
              </VStack>
            </HStack>
          </VStack>
      
          <Table
            loading={loadingList}
            rowClassName={rowClassName}
            size="small"
            pagination={false}
            scroll={{ x: 1300 }}
            style={{ width: "100%" }}
            columns={columns}
            dataSource={dadosHome}
          />
          <VStack my={4}>
            <Pagination
              total={total}
              showTotal={(total) => `Total de ${total} itens`}
              defaultPageSize={10}
              defaultCurrent={1}
              onChange={onChange}
              current={currentPage}
              locale={{ items_per_page: "/ Página"}}
            />
          </VStack>
        </VStack>
      </VStack>
      <Modal
        size={"lg"}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Criar versão de produção</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack overflow={"auto"} maxH={300}>
              <FormControl>
                <FormLabel>Título</FormLabel>
                <Input ref={initialRef} placeholder="Versão x-yz" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Versão</FormLabel>
                <Input placeholder="Last name" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Descrição</FormLabel>
                <Input placeholder="Last name" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Nome do arquivo</FormLabel>
                <Input placeholder="Last name" />
              </FormControl>
              <FormControl>
                <FormLabel>Descrição</FormLabel>
                <Textarea placeholder="Descreva a versão" size="sm" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>
                  Por favor, digite "eu confirmo que desejo lançar essa versão"
                </FormLabel>
                <Input placeholder="" />
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3}>
              Lançar
            </Button>
            <Button onClick={onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenFilter} onClose={onCloseFilter}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>First name</FormLabel>
              <Input ref={initialRef} placeholder="First name" />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Last name</FormLabel>
              <Input placeholder="Last name" />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {DrawerModal}
    </>
  );
};
